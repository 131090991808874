import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from 'src/api-urls/urls';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';

@Injectable({
	providedIn: 'root'
})
export class APIPortalUserPreferencesService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	get<T>(
		id: PreferencesId,
		attrs: any
	): Observable<HttpResponse<T>> {
		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: `${ApiUrls.portal.utilsUserPreferences}/${id}`,
			headears: attrs.headers || {},
			extras: attrs.extras || {},
			params: attrs.params || {}
		});
	}

	post<T>(
		id: PreferencesId,
		data: T,
		attrs: any
	): Observable<HttpResponse<T>> {
		return this.appHttpRequestService.getRequest({
			method: 'post',
			url: `${ApiUrls.portal.utilsUserPreferences}/${id}`,
			payload: data,
			headears: attrs.headers || {},
			extras: attrs.extras || {},
			params: attrs.params || {}
		});
	}
}


export interface UserPreferencesServiceOrderOperationsFavorites {
	favoritesOperationCode: number[];
}
export interface MainControllerUserPreferences {
	sideNavBehaviorMinimized: boolean;
}

export enum PreferencesId {
	SERVICE_ORDER_OPERATION_FAVORITES = 'SERVICE_ORDER_OPERATION_FAVORITES',
	SERVICE_ORDER_CALENDAR_FILTER = 'SERVICE_ORDER_CALENDAR_FILTER',
	TECH_USER_PLANNING_FILTER_MODEL = 'TECH_USER_PLANNING_FILTER_MODEL',
	MAIN_CONTROLLER_PREFERENCES = 'MAIN_CONTROLLER_PREFERENCES'
}
