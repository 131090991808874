import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { APIPortalUtilsAddressCountriesGETItem, APIPortalUtilsAddressCountriesService } from "src/app/api-services/portal/utils/address-countries";
import { SelectVirtualScrollObj, selectVirtualScrollLoadData } from "../select-virtual-scroll/select-virtual-scroll.helper";
import { AbstractControl, FormControl } from "@angular/forms";
import { AutoUnsubscribeOnDestroy, autoUnsubscribe } from "src/app/helpers/auto-unsubscribe";
import { Subscription, map } from "rxjs";
import { APIPortalUtilsAddressZipcodeGETRoot, APIPortalUtilsAddressZipcodeService } from "src/app/api-services/portal/utils/address-zipcode";
import { APIPortalUtilsAddressLatLongService } from "src/app/api-services/portal/utils/address-latlong";
import { AppDeviceTypeService } from "src/app/app.device-type.service";

@Component({
	selector: 'form-control-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class FormControlAddressComponent implements OnInit, OnDestroy, AutoUnsubscribeOnDestroy {

	obs: Subscription[] = [];

	selectCountries: SelectVirtualScrollObj<APIPortalUtilsAddressCountriesGETItem> = {
		ready: false,
		dataProperties: {
			id: 'COUNTRY_CODE',
			desc: 'COUNTRY_DESC'
		},
		subscription: null,
		disabled: true,
		data: [],
		keepDisabledAfterLoad: true
	};

	loadingLatLong = false;
	zipCodeKeyUpTimer = null;
	addressrouteKeyUpTimer = null;
	geolocationTimer = null;
	hasAddress = false;
	collectedAddressFromZipCode = {
		loading: false,
		manual: true,
		manualLatLong: true,
		formatted_address: '',
		address: '',
		district: '',
		city: '',
		state: ''
	};

	device = this.appDeviceTypeService.device;

	@Input({required: true}) formControls: FormControlAddressComponentInputs;

	constructor(
		private appDeviceTypeService: AppDeviceTypeService,
		private apiPortalUtilsAddressCountriesService: APIPortalUtilsAddressCountriesService,
		private apiPortalUtilsAddressZipcodeService: APIPortalUtilsAddressZipcodeService,
		private apiPortalUtilsAddressLatLongService: APIPortalUtilsAddressLatLongService
	){}

	ngOnInit() {

		this.onValuesChanges();

		this.selectCountries.formControl = this.formControls.countryId;

		selectVirtualScrollLoadData(
			this.selectCountries,
			(filters, attrs) => this.apiPortalUtilsAddressCountriesService.get({})
				.pipe(map(result => {
					if(!this.selectCountries.formControl.value){
						result.body.data.map(item => {
							if(item.COUNTRY_CUSTOMER){
								this.selectCountries.formControl.setValue(item.COUNTRY_CODE);
							}
						});
					}
					return result;
				})),
			null,
			100,
			'data',
			false,
			this
		);


	}

	private onValuesChanges(){

		this.formControls.zipcode.valueChanges.subscribe(zipcode => this.zipCodePreProcessor(zipcode));

		this.formControls.street.valueChanges.subscribe(() => this.onAddressManualChanged());

		this.formControls.streetnumber.valueChanges.subscribe(() => this.fillAddressGeolocation());

		this.formControls.countryId.valueChanges.subscribe(() => {
			if(!this.collectedAddressFromZipCode.manual){
				this.zipCodePreProcessor(this.formControls.zipcode.value.zipcode);
			}
		});

		this.formControls.latitude.valueChanges.subscribe(() => this.onGeoLocationManualChanged());
		this.formControls.longitude.valueChanges.subscribe(() => this.onGeoLocationManualChanged());
		this.formControls.plus_code.valueChanges.subscribe(() => this.onGeoLocationManualChanged());

	}

	private zipCodePreProcessor(zipcode: string){

		if(!zipcode || zipcode.length < 5){
			return;
		}

		clearTimeout(this.zipCodeKeyUpTimer);

		this.collectedAddressFromZipCode = {
			loading: false,
			manual: false,
			manualLatLong: false,
			formatted_address: '',
			address: '',
			district: '',
			city: '',
			state: ''
		};

		this.zipCodeKeyUpTimer = setTimeout(
			() => {
				this.formControls.streetnumber.setValue('', {emitEvent: false});
				this.formControls.latitude.setValue('', {emitEvent: false});
				this.formControls.longitude.setValue('', {emitEvent: false});
				this.formControls.plus_code.setValue('', {emitEvent: false});
				this.fillAddressWithZipCode(zipcode);
			},
			500
		);

	}

	private fillAddressWithZipCode(zipcode: string){

		if(!zipcode){
			return;
		}

		this.collectedAddressFromZipCode.loading = true;
		this.apiPortalUtilsAddressZipcodeService.get(
			{
				zipcode,
				countryCode: this.formControls.countryId.value
			},
			{
				extras: {
					friendlyMessage: {
						notFound: false
					}
				}
			}
		).subscribe({
			next: result => {
				this.collectGoogleApiGeoCodeAddress(result.body);
				this.collectedAddressFromZipCode.loading = false;
			},
			error: error => {
				console.log(error);
				this.collectedAddressFromZipCode.loading = false;
			}
		});

	}

	private collectGoogleApiGeoCodeAddress(result: APIPortalUtilsAddressZipcodeGETRoot){

		if(!this.collectedAddressFromZipCode.address){
			this.collectedAddressFromZipCode.manual = true;
		}

		this.formControls.street.setValue(result.street, {emitEvent: false});
		this.formControls.streetnumber.setValue('', {emitEvent: false});
		this.formControls.district.setValue(result.district, {emitEvent: false});
		this.formControls.state.setValue(result.state, {emitEvent: false});
		this.formControls.city.setValue(result.city, {emitEvent: false});

	}

	private fillGeolocation(){

		if(
			!this.formControls.streetnumber.value
			||
			this.collectedAddressFromZipCode.manualLatLong
		){
			return;
		}

		this.formControls.latitude.setValue(null, {emitEvent: false});
		this.formControls.longitude.setValue(null, {emitEvent: false});
		this.formControls.plus_code.setValue(null, {emitEvent: false});

		this.loadingLatLong = true;

		this.apiPortalUtilsAddressLatLongService.get(
			{
				countryCode: this.formControls.countryId.value,
				city: this.formControls.city.value,
				state: this.formControls.state.value,
				number: this.formControls.streetnumber.value,
				district: this.formControls.district.value,
				street: this.formControls.street.value,
				zipcode: this.formControls.zipcode.value
			},
			{
				extras: {
					friendlyMessage: {
						notFound: false
					}
				}
			}
		).subscribe({
			next: result => {
				this.loadingLatLong = false;
				this.formControls.latitude.setValue(
					result.body.lat || null, {emitEvent: false}
				);
				this.formControls.longitude.setValue(
					result.body.lng || null, {emitEvent: false}
				);
				this.formControls.plus_code.setValue(
					result.body.plus_code || null, {emitEvent: false}
				);
				this.formControls.district.setValue(
					result.body.district || this.formControls.district.value, {emitEvent: false}
				);
			},
			error: error => {
				console.log(error);
				this.loadingLatLong = false;
			}
		});

	}

	private fillManualAddressGeolocation(){
		this.fillGeolocation();
	}

	private fillAddressGeolocation(){

		clearTimeout(this.geolocationTimer);

		if(this.collectedAddressFromZipCode.manual){
			this.geolocationTimer = setTimeout(() => {
				this.fillManualAddressGeolocation();
			}, 500);
			return;
		}

		if(
			this.formControls.street.value
			&&
			this.formControls.streetnumber.value
			&&
			this.collectedAddressFromZipCode.address
			&&
			this.collectedAddressFromZipCode.formatted_address
		){
			this.geolocationTimer = setTimeout(() => {
				console.log('fillAddressGeolocation');
				this.fillGeolocation();
			}, 500);
		}
	}

	private onAddressManualChanged(){

		this.collectedAddressFromZipCode.manual = true;

		clearTimeout(this.addressrouteKeyUpTimer);

		if(this.collectedAddressFromZipCode.manual){
			this.addressrouteKeyUpTimer = setTimeout(
				() => {
					this.fillManualAddressGeolocation();
				},
				500
			);
		}

	}

	private onGeoLocationManualChanged(){
		console.log('onGeoLocationManualChanged');
		this.collectedAddressFromZipCode.manualLatLong = true;
	}

	resetFormControlAddress(event: MouseEvent) {
		event.stopPropagation();

		const {
			zipcode,
			street,
			streetnumber,
			complement,
			district,
			city,
			state,
			latitude,
			longitude,
			plus_code
		} = this.formControls;

		zipcode.patchValue(null);
		street.patchValue(null);
		streetnumber.patchValue(null);
		complement.patchValue(null);
		district.patchValue(null);
		city.patchValue(null);
		state.patchValue(null);
		latitude.patchValue(null);
		longitude.patchValue(null);
		plus_code.patchValue(null);
	}

	ngOnDestroy(): void {
		autoUnsubscribe(this);
	}

}

export interface FormControlAddressComponentInputs {
	countryId: AbstractControl;
	zipcode: FormControl<any>;
	street: FormControl<any>;
	streetnumber: FormControl<any>;
	complement: FormControl<any>;
	district: FormControl<any>;
	state: FormControl<any>;
	city: FormControl<any>;
	contact?: FormControl<any>;
	phone?: FormControl<any>;
	latitude: FormControl<any>;
	longitude: FormControl<any>;
	plus_code: FormControl<any>;
}
