<ng-template #defaultTemplate let-item="item" let-properties="properties">
	{{item[properties['desc']]}}
	<span *ngIf="item[properties['descExtra1']]">
		({{item[properties['descExtra1']]}})
	</span>
</ng-template>

<loading-spinner
	*ngIf="loading && !isDisabled()"
	size="24"
	overlay-fill="no-translucid"
></loading-spinner>

<mat-form-field
	layout="column"
	[attr.no-margin]="!defaultMargins ? '' : null"
	[attr.wrapper-no-padding-bottom]="!defaultMargins ? '' : null"
	[attr.wrapper-no-margin]="!defaultMargins ? '' : null"
	[attr.mat-select-chip]="attrMatSelectChip ? '' : null"
	[attr.mat-select-reset-button]="attrMatSelectResetButton ? '' : null"
	[ngClass]="{
		'has-value': hasValue(),
		'select-virtual-scroll-mat-form-field-invalid': isInvalid()
	}"
	mat-form-field-select-virtual-scroll
>
	<mat-label>{{label}}</mat-label>
	<mat-select
		#matSelect
		(openedChange)="openedChange($event)"
		panelClass="
			select-panel-virtual-scroll
			{{panelClass}}
			{{panelNoMaxWidth ? 'panel-no-max-width' : '' }}
		"
		[(value)]="model[key]"
		[disabled]="loading || isDisabled()"
		[multiple]="multiple"
		[required]="required"
		[ngClass]="{
			'mat-select-invalid': isInvalid()
		}"
	>

		<mat-option
			*ngIf="!multiple"
			#optionWorkaround
			[value]="model[key]"
			class="select-virtual-mat-option-workaround"
		>
			<ng-container
				[ngTemplateOutlet]="optionTemplate || defaultTemplate"
				[ngTemplateOutletContext]="{ item: selected, properties: dataProperties }"
			>
			</ng-container>
		</mat-option>

		<mat-select-trigger
			*ngIf="!loading && !multiple || isDisabled() && hasValue()"
			layout="row"
		>
			<ng-container
				[ngTemplateOutlet]="triggerTemplate || optionTemplate || defaultTemplate"
				[ngTemplateOutletContext]="{ item: selected, properties: dataProperties }"
			>
			</ng-container>
		</mat-select-trigger>

		<mat-select-trigger
			*ngIf="multiple"
			layout="row"
		>


			<span
				*ngIf="!selected[dataProperties['desc']]"
				text-overflow-ellipsis-wrapper
			>
				<span text-overflow-ellipsis>
					{{label}}
				</span>
				<span class="mat-small">
					({{model[key].length}})
				</span>
			</span>

			<span
				*ngIf="selected[dataProperties['desc']]"
				text-overflow-ellipsis-wrapper
			>
				<span text-overflow-ellipsis>
					<ng-container
						[ngTemplateOutlet]="optionTemplate || defaultTemplate"
						[ngTemplateOutletContext]="{ item: selected, properties: dataProperties }"
					>
					</ng-container>
				</span>
				<span
					*ngIf="model[key].length > 1"
					class="mat-small"
				>
					(+{{model[key].length-1}})
				</span>
			</span>

		</mat-select-trigger>

		<div
			*ngIf="multiple && !opened"
			class="select-virtual-multiple-options-workaround"
		>
			<mat-option>
				workaround multiple
			</mat-option>
			<mat-option
				*ngFor="let item of model[key]"
				[value]="item"
			>
				workaround multiple
			</mat-option>
		</div>

		<div
			#wrapperVirtualScroll
			class="wrapper-virtual-scroll"
			(keydown)="wrapperVirtualScrollOnKeyDown($event)"
		>

			<loading-spinner
				*ngIf="loadingAddNew"
				size="24"
				overlay-fill
			></loading-spinner>

			<mat-form-field
				*ngIf="!addingNew && !editingItem"
				appearance="fill"
				wrapper-no-padding-bottom
				input-fill-height
			>
				<mat-label>
					{{'Components.Select.Search' | transloco}}
					<span *ngIf="loading">...</span>
				</mat-label>
				<input
					#inputSearch
					(keyup)="search($event, inputSearch.value)"
					[(ngModel)]="searchKeyword.keyword"
					(ngModelChange)="inputSearchOnModelChange($event)"
					matInput
					autofocus="false"
					autocomplete="off"
				>
			</mat-form-field>

			<form>
				<mat-form-field
					*ngIf="addingNew"
					appearance="fill"
					wrapper-no-padding-bottom
				>
					<mat-label>
						{{'Components.SelectVirtualScroll.AddNewLabel' | transloco:{label} }}
					</mat-label>
					<input
						#inputNewItem
						[(ngModel)]="addNewModel.description"
						matInput
						autocomplete="off"
						[maxlength]="addNewItem.inputOptions?.maxLength || defaultAddNewIptMaxLength"
						[ngModelOptions]="{standalone: true}"
					>
				</mat-form-field>

				<mat-form-field
					*ngIf="editingItem"
					appearance="fill"
					wrapper-no-padding-bottom
				>
					<mat-label
						[ngClass]="{
							'deleting-item': deletingItem
						}"
					>
						<ng-container *ngIf="!deletingItem">
							{{'Components.SelectVirtualScroll.EditLabel' | transloco:{label} }}
						</ng-container>
						<ng-container *ngIf="deletingItem">
							{{'Components.SelectVirtualScroll.DeleteConfirm' | transloco}}
						</ng-container>
					</mat-label>
					<input
						#inputNewItem
						[(ngModel)]="editItemModel.model[dataProperties.desc]"
						matInput
						autocomplete="off"
						[disabled]="deletingItem"
						[maxlength]="addNewItem.inputOptions?.maxLength || defaultAddNewIptMaxLength"
						[ngModelOptions]="{standalone: true}"
					>
				</mat-form-field>

				<div
					*ngIf="showAddNewButton || editItem"
					layout="column" layout-padding
					class="btn-add-edit-wrapper"
				>

					<button
						*ngIf="showAddNewButton && !loadingSearchItems && !addingNew && !editingItem"
						type="button"
						mat-raised-button color="primary"
						(click)="setModeToAddNew()"
					>
						{{
							addNewItem.buttonLabel
							? addNewItem.buttonLabel
							: ('Components.SelectVirtualScroll.AddNewLabel' | transloco:{label})
						}}
					</button>

					<div
						*ngIf="addingNew"
						layout="row"
					>
						<button
							layout="column"
							flex
							mat-stroked-button
							type="button"
							(click)="setModeToSelect()"
						>{{'Components.Labels.ButtonCancel' | transloco }}</button>
						<button
							layout="column"
							flex
							type="submit"
							mat-flat-button color="primary"
							[disabled]="!addNewModel.description.length"
							(click)="submitNewItem()"
						>{{'Components.Labels.ButtonAdd' | transloco }}</button>
					</div>

					<div
						*ngIf="editingItem"
						layout="row"
					>

						<ng-container *ngIf="!deletingItem">
							<button
								layout="column"
								flex
								type="button"
								mat-stroked-button
								(click)="setModeToSelect()"
							>{{'Components.Labels.ButtonCancel' | transloco }}</button>
							<button
								*ngIf="editItem?.deleteKey"
								layout="column"
								flex="none"
								type="button"
								mat-flat-button color="warn"
								(click)="setModeDeleteItem()"
							>
								<mat-icon
									svgIcon="delete_forever" svg-same-size
									no-margin
								></mat-icon>
							</button>
							<button
								layout="column"
								flex
								type="submit"
								mat-flat-button color="primary"
								[disabled]="!editItemModel.model[dataProperties.desc].length"
								(click)="submitEditItem()"
							>{{'Components.Labels.ButtonUpdate' | transloco }}</button>
						</ng-container>

						<ng-container *ngIf="deletingItem">
							<button
								layout="column"
								flex
								mat-stroked-button
								type="button"
								(click)="setModeToSelect()"
							>{{'Components.Labels.ButtonNo' | transloco }}</button>
							<button
								layout="column"
								flex
								mat-flat-button color="warn"
								type="button"
								(click)="submitEditItem()"
							>{{'Components.Labels.ButtonYes' | transloco }}</button>
						</ng-container>

					</div>

				</div>
			</form>

			<div
				*ngIf="searchItem"
				class="search-item-hint"
			>
				<span *ngIf="loadingSearchItems || searchKeyword.keyword.length < searchKeyword.minLength">
					{{'Components.SelectVirtualScroll.SearchItem.HintMinChars' | transloco}}
				</span>
				<span *ngIf="
					!loadingSearchItems
					&&
					searchKeyword.keyword.length >= searchKeyword.minLength
					&&
					!searchResult.length
				">
					{{'Components.SelectVirtualScroll.SearchItem.NotFound' | transloco}}
				</span>
				<span *ngIf="
					!loadingSearchItems
					&&
					searchKeyword.keyword.length >= searchKeyword.minLength
					&&
					searchResult.length
				">
					{{'Components.SelectVirtualScroll.SearchItem.TotalResult' | transloco:{value: searchResult.length} }}
					<span *ngIf="searchItem.hiddenSearchResult">
						<span *ngIf="searchItem.hiddenSearchResult === 1">
						{{'Components.SelectVirtualScroll.SearchItem.OmitResultSingular' | transloco:{value: searchItem.hiddenSearchResult} }}
						</span>
						<span *ngIf="searchItem.hiddenSearchResult > 1">
						{{'Components.SelectVirtualScroll.SearchItem.OmitResultPlural' | transloco:{value: searchItem.hiddenSearchResult} }}
						</span>
					</span>
				</span>
			</div>

			<mat-action-list *ngIf="!addingNew && !editingItem">

				<loading-spinner
					*ngIf="loadingSearchItems"
					size="60"
				></loading-spinner>

				<cdk-virtual-scroll-viewport
					*ngIf="!loading && !loadingSearchItems"
					[itemSize]="scrollItemSize"
					class="select-virtual-scroll-viewport"
					[ngClass]="{
						'add-edit': showAddNewButton || editItem
					}"
				>
					<mat-option
						*cdkVirtualFor="
							let item of searchResult;
							templateCacheSize: 0
						"
						[value]="item[dataProperties['id']]"
						(click)="selectItem(item)"
						(keydown)="selectItemKeydown($event, item)"
						[ngClass]="getMatOptionClass(item)"
						tabindex="0"
						no-space-selection
					>
						<div class="select-multiple-lines">
							<span
								[title]="
									item[dataProperties['desc']]
									+ (
											!!item[dataProperties['descExtra1']]
											? ' (' + item[dataProperties['descExtra1']] + ')'
											: ''
										)
								"
								text-overflow-ellipsis
							>
								<ng-container
									[ngTemplateOutlet]="optionTemplate || defaultTemplate"
									[ngTemplateOutletContext]="{ item: item, properties: dataProperties }"
								>
								</ng-container>
							</span>
						</div>
						<mat-icon
							*ngIf="editItem && (!editItem.showEditButtonWhen || editItem.showEditButtonWhen(item))"
							svgIcon="edit"
							mat-ripple
							class="btn-edit"
							(click)="setModeToEditItem($event, item)"
						></mat-icon>
					</mat-option>
				</cdk-virtual-scroll-viewport>
			</mat-action-list>
		</div>
	</mat-select>
	<mat-icon *ngIf="attrMatSelectResetButton && !isDisabled()" (click)="reset($event)" flex="none" svgIcon="close" matSuffix class="button-reset"></mat-icon>
</mat-form-field>
