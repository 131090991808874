import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';
import { combineLatest, Observable, Subscription, tap } from 'rxjs';
import { APIPagination } from 'src/app/helpers/api-pagination.interface';
import { APItranslate, APItranslateRoot } from 'src/app/helpers/api-translate.interface';
import { autoUnsubscribe, AutoUnsubscribeOnDestroy, getObservableAndAutoUnsubscribe } from 'src/app/helpers/auto-unsubscribe';
import { FilterData } from 'src/app/helpers/search-text';

const defaultAttrsValues = {
	loading: true,
	getAllPages: true,
	getPageLimit: 10,
	pageConfigSize: 5,
	hasTranslate: false
};

@Component({
	selector: 'data-list',
	templateUrl: './data-list.component.html',
	styleUrls: ['./data-list.component.scss']
})
export class DataListComponent implements OnInit, OnDestroy, AutoUnsubscribeOnDestroy {

	obs: Subscription[] = [];
	error: any;
	loading = true;

	data: any[];
	filteredData: any[];
	pageData: any[] = [];

	translate: APItranslateRoot<any>;

	@Input() attrs: DataListAttrs;
	@Input() body: TemplateRef<any>;
	@Input() row: TemplateRef<any>;
	@Input() colsHeader: {
		[prop: string]: TemplateRef<any>;
	};
	@Input() cols: {
		[prop: string]: TemplateRef<any>;
	};
	@Input() set searchKeyword(text: string){
		this.setSearchResult(text);
	};

	pageConfig = {
		pageSize: 5,
		length: 0,
		pageIndex: 0,
		startIndex: 0,
		endIndex: 0
	};

	constructor(
		private translocoService: TranslocoService
	) {
	}

	ngOnInit() {
		if(this.attrs){
			this.setAttrsDefaultValues();
			this.attrs.searchItems = () => this.setSearchResult();
			this.load();
		}
	}

	private setAttrsDefaultValues(){

		Object.assign(
			this.attrs,
			Object.assign(
				{...defaultAttrsValues},
				this.attrs
			)
		);

		this.pageConfig.pageSize = this.attrs.pageConfigSize;

	}

	getColHeader(col): TemplateRef<any>{
		return this.colsHeader?.[col];
	}

	getColHeaderName(col): string{
		return this.attrs.colsHeader?.[col] || col;
	}

	getCol(col): TemplateRef<any>{
		return this.cols?.[col];
	}

	getTranslate(colName): APItranslate{
		return this.translate?.[colName];
	}

	private setTranslate(root: any){
		if(this.attrs.hasTranslate && root.translate){
			this.translate = root.translate;
		}
	}

	// eslint-disable-next-line max-lines-per-function
	private load(page: number = 1){

		this.error = null;
		this.loading = true;

		const loadFirstPageSubscription = this.attrs.get(
			this.attrs.filters,
			{
				params: {
					limit: this.attrs.getAllPages
						? this.attrs.getAllPagesLimitSize
						: this.attrs.pageConfigSize,
					page
				},
				extras: {injectFriendlyMessageIntoResponseWhenEmpty: true}
			}
		)
			.pipe(tap(r => {
				if(!r.body[this.attrs.itemsCollectionKey]?.length){
					this.error = {
						friendlyMessage: {
							message: this.attrs.label
								? this.translocoService.translate(
									'DefaultHttpRequestMessages.notFoundLabel',
									{label: this.attrs.label}
								)
								: this.translocoService.translate(
									'DefaultHttpRequestMessages.notFound'
								)
						}
					};
				}
			}
			))
			.subscribe({
				// eslint-disable-next-line max-lines-per-function
				next: result => {

					this.setTranslate(result.body);

					if(!this.attrs.getAllPages){
						this.data = result.body[this.attrs.itemsCollectionKey];
						this.setPageConfigGetAllPagesFalse(result.body.links);
						this.loading = false;
						return;
					}

					const allPages: Observable<any>[] = [
						new Observable(subscriber => {
							subscriber.next({
								body: result.body
							});
						})
					];

					if(this.attrs.getAllPages && result.body.links?.totalPages){
						for(let i=2; i<=result.body.links.totalPages; i++){
							allPages.push(
								this.attrs.get(this.attrs.filters,{
									params: {
										limit: this.attrs.getAllPagesLimitSize,
										page: i
									},
									extras: {injectFriendlyMessageIntoResponseWhenEmpty: true}
								})
							);
						}
					}

					const allPagesSubs = combineLatest(allPages)
						.subscribe({next: allPagesResult => {

							this.filteredData = this.data = allPagesResult.reduce((prev, current) => {
								prev.push(...current.body[this.attrs.itemsCollectionKey]);
								return prev;
							}, []);

							this.sortItems();
							this.setSearchResult();
							this.loading = false;

						}, error: allPagesResultError => this.onLoadError(allPagesResultError)});
						// }, error: allPagesResultError => console.log(allPagesResultError)});

					getObservableAndAutoUnsubscribe(this, allPagesSubs);

				},
				error: result => this.onLoadError(result)
			});

		getObservableAndAutoUnsubscribe(this, loadFirstPageSubscription);

	}

	private onLoadError(resultError){
		this.error = resultError.error;
		this.loading = false;
	}

	protected setSearchResult(keyword: string = this.attrs.search?.searchKeyword || ''){

		if(!this.attrs.getAllPages){
			this.load(1);
			return;
		}

		this.attrs.search.searchKeyword = keyword;

		if(this.data){

			// if(!keyword){
			// 	this.filteredData = this.data;
			// 	this.setPageConfig();
			// 	return;
			// }

			this.filteredData = FilterData(
				keyword,
				this.data,
				this.attrs.search.textItem ? (item) => this.attrs.search.textItem(item) : null,
				(item, keywordRegexp) => this.attrs.search.customFilters(item, keywordRegexp)
			);

			this.setPageConfig();

		}

	}

	private setPageConfig(pageEvent: PageEvent = {
		length: 0,
		pageIndex: 0,
		pageSize: 0,
		previousPageIndex: 0
	}){

		this.pageConfig.length = this.filteredData.length;
		this.pageConfig.pageIndex = pageEvent.pageIndex;
		this.pageConfig.startIndex = pageEvent.pageIndex * this.pageConfig.pageSize;
		this.pageConfig.endIndex = this.pageConfig.startIndex + this.pageConfig.pageSize;

		if(this.pageConfig.endIndex > this.pageConfig.length){
			this.pageConfig.endIndex = this.pageConfig.length;
		}

		this.pageData.splice(0);
		this.pageData.push(
			...this.filteredData.slice(this.pageConfig.startIndex, this.pageConfig.endIndex)
		);

	}

	private setPageConfigGetAllPagesFalse(pagination: APIPagination){
		this.pageData = this.data;
		if(pagination){
			this.pageConfig.length = pagination.totalRows || this.pageConfig.length;
			this.pageConfig.pageIndex = pagination.currentPage - 1;
		}else{
			this.pageConfig.length = 0;
			this.pageConfig.pageIndex = 1;
		}
	}

	onPageChange($event: PageEvent){
		// this.pageConfig.pageIndex = $event.pageIndex;
		if($event.pageSize){
			this.pageConfig.pageSize = $event.pageSize;
		}
		if(this.attrs.getAllPages){
			this.setPageConfig($event);
		}else{
			this.attrs.pageConfigSize = this.pageConfig.pageSize;
			this.load($event.pageIndex + 1);
		}
		// this.load($event.pageIndex + 1);
	}

	sortItems() {
		if(this.attrs.sortItems) {
			this.attrs.sortItems(this.data);
		}
	}

	ngOnDestroy(): void {
		autoUnsubscribe(this);
	}

}

export interface DataListAttrs<ROOT = any, ITEM = any> {
	label?: string;
	filters: any;
	pageConfigSize?: number;
	getAllPagesLimitSize?: number;
	getAllPages?: boolean;
	itemsCollectionKey: keyof Omit<ROOT, 'links' | 'translate'>;
	get: (filters, params) => Observable<HttpResponse<ROOT> | {body: ROOT}>;
	search?: {
		searchKeyword: string;
		textItem: (item: ITEM) => string;
		customFilters: (item: ITEM, searchKeywordRegexp: RegExp) => boolean;
	};
	cols: (keyof ITEM)[];
	colsHeader?: {
		[key: string]: string;
	};
	hasTranslate?: boolean;
	searchItems?: () => void;
	sortItems?: (data: ITEM[]) => void;
}
