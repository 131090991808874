<div
	layout= column
>

	<div
		layout="row"
		layout-margin
		no-margin
		class="country-wrapper"
	>

		<div
			layout="column"
			layout-margin
			no-margin-left
			no-margin-bottom
			flex
		>
			<select-virtual-scroll
				no-margin
				[label]="'Route.ServiceOrderPlanning.Routes.AdminSites.IptCountryLabel' | transloco"
				[required]="true"
				[data]="selectCountries.data"
				[dataProperties]="selectCountries.dataProperties"
				[selectVistualScrollFormControl]="selectCountries.formControl"
				[isReady]="selectCountries.ready"
				[panelNoMaxWidth]="true"
				[defaultMargins]="true"
			>
			</select-virtual-scroll>
		</div>

		<mat-form-field
			layout="column"
			flex="none"
			class="field-zipcode hide-standard-spinner"
			no-margin-right
		>
			<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptPostalCode' | transloco}}</mat-label>
			<input
				type="string" matInput autocomplete="off" maxlength="20"
				[formControl]="formControls.zipcode"
			>
			<mat-icon
				*ngIf="formControls.zipcode.value && formControls.zipcode.enabled"
				(click)="resetFormControlAddress($event)"
				matSuffix
				svgIcon="ticket-status-canceled"
			></mat-icon>

			<mat-hint *ngIf="collectedAddressFromZipCode.loading">
				<div layout="row" layout-align="start center" class="ipt-loading-spinner">
					<loading-spinner size="14"></loading-spinner>
					{{'Route.ServiceOrderPlanning.Routes.AdminSites.LoadingAddress' | transloco}}
				</div>
			</mat-hint>
		</mat-form-field>

	</div>

	<mat-form-field>
		<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptAddressLabel' | transloco}}</mat-label>
		<input
			type="text" matInput autocomplete="off" maxlength="200"
			[formControl]="formControls.street"
		>
	</mat-form-field>

	<div
	layout="row"
	layout-margin
	no-margin
	>
		<mat-form-field
			layout="column"
			input-width-auto
			flex-xs="30"
			flex-gt-xs="20"
			class="hide-standard-spinner"
			no-margin-top
			no-margin-left
		>
			<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptAddressNumber' | transloco}}</mat-label>
			<input
				type="text" matInput autocomplete="off" maxlength="20"
				[formControl]="formControls.streetnumber"
			>
		</mat-form-field>

		<mat-form-field
			layout="column"
			input-width-auto
			flex
			no-margin-top
			no-margin-right
		>
			<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptAddressComplement' | transloco}}</mat-label>
			<input
				type="text" matInput autocomplete="off" maxlength="100"
				[formControl]="formControls.complement"
			>
		</mat-form-field>
	</div>

	<mat-form-field>
		<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptAddressDistrict' | transloco}}</mat-label>
		<input
			type="text" matInput autocomplete="off" maxlength="100"
			[formControl]="formControls.district"
		>
	</mat-form-field>

	<div
	layout="row"
	layout-margin
	no-margin
	>

		<mat-form-field
			layout="column"
			input-width-auto
			flex
			no-margin-top
			no-margin-left
		>
			<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptAddressCity' | transloco}}</mat-label>
			<input
				type="text" matInput autocomplete="off" maxlength="100"
				[formControl]="formControls.city"
			>
		</mat-form-field>

		<mat-form-field
			layout="column"
			input-width-auto
			flex-xs="30"
			flex-gt-xs="20"
			no-margin-top
			no-margin-right
		>
			<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptState' | transloco}}</mat-label>
			<input
				type="text" matInput autocomplete="off" maxlength="50"
				[formControl]="formControls.state"
			>
		</mat-form-field>

	</div>

	<div
		layout="row"
		layout-margin
		no-margin
		class="row-geolocation"
	>

		<div
			layout="row"
			layout-margin
			no-margin
			flex
		>
			<mat-form-field
				layout="column"
				input-width-auto
				flex
				no-margin-top
				no-margin-left
				class="hide-standard-spinner"
			>
				<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptLatitude' | transloco}}</mat-label>
				<input
					type="number" matInput autocomplete="off"
					[formControl]="formControls.latitude"
				>
				<mat-hint
					*ngIf="loadingLatLong"
				>
					<div
						layout="row" layout-align="start center" flex="100" class="ipt-loading-spinner"
					>
						<loading-spinner size="14"></loading-spinner>
						{{'Route.ServiceOrderPlanning.Routes.AdminSites.LoadingGeoLocation' | transloco}}
					</div>
				</mat-hint>
			</mat-form-field>
		</div>

		<div
			layout="row"
			layout-margin
			no-margin
			flex
		>
			<mat-form-field
				layout="column"
				input-width-auto
				flex
				no-margin-top
				class="hide-standard-spinner"
			>
				<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptLongitude' | transloco}}</mat-label>
				<input
					type="number" matInput autocomplete="off"
					[formControl]="formControls.longitude"
				>
			</mat-form-field>

			<a
				[disabled]="!formControls.latitude.value || !formControls.longitude.value"
				[attr.href]="'https://www.google.com/maps/search/?api=1&query='+formControls.latitude.value+','+formControls.longitude.value"
				target="_blank"
				layout-align="center center"
				flex="none"
				mat-icon-button
				color="primary"
				no-margin-right
				no-margin-left
			>
				<mat-icon flex="none" svgIcon="pin_drop"></mat-icon>
			</a>
		</div>

		<div
			layout="row"
			layout-margin
			no-margin
			flex
		>
			<mat-form-field
				layout="column"
				input-width-auto
				flex
				no-margin-top
				no-margin-right
			>
				<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptGooglePlusCode' | transloco}}</mat-label>
				<input
					type="text" matInput autocomplete="off"
					[formControl]="formControls.plus_code"
				>
			</mat-form-field>

			<a
				[disabled]="!formControls.plus_code.value"
				[attr.href]="'https://plus.codes/'+formControls.plus_code.value"
				target="_blank"
				layout-align="center center"
				flex="none"
				mat-icon-button
				color="primary"
				no-margin-right
				no-margin-left
			>
				<mat-icon flex="none" svgIcon="pin_drop"></mat-icon>
			</a>
		</div>

	</div>

	<mat-form-field *ngIf="formControls.contact">
		<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptContact' | transloco}}</mat-label>
		<input
			type="text" matInput autocomplete="off" maxlength="200"
			[formControl]="formControls.contact"
		>
	</mat-form-field>

	<mat-form-field *ngIf="formControls.phone">
		<mat-label>{{'Route.ServiceOrderPlanning.Routes.AdminSites.IptPhoneNumber' | transloco}}</mat-label>
		<input
			type="text" matInput autocomplete="off" maxlength="30"
			[formControl]="formControls.phone"
		>
	</mat-form-field>

</div>
