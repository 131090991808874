import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { APIPortalUserPreferencesService, PreferencesId, MainControllerUserPreferences } from 'src/app/api-services/portal/utils/user-preference.service';

@Injectable({
	providedIn: 'root'
})
export class MainControllerService {

	private _backButton: boolean | string = false;

	navigationBar = false;
	sideNav: MatSidenav;

	private _userPreferences: MainControllerUserPreferences = {
		sideNavBehaviorMinimized: false
	}

	get userPreferences() {
		return this._userPreferences;
	}

	userPreferencesLoaded = new Subject<boolean>();

	matSidenavContent: MatSidenavContent;
	ePreferencesId = PreferencesId;

	private rightIconsComponentAttached: Type<any>;
	rightIconsRef: ViewContainerRef;

	constructor(
		private apiPortalUserPreferencesService: APIPortalUserPreferencesService
	) {}

	postSideNavBehaviorOnPreferences() {
		this.setUserPreferences();

		this.apiPortalUserPreferencesService.post<MainControllerUserPreferences>(
			this.ePreferencesId.MAIN_CONTROLLER_PREFERENCES,
			this._userPreferences,
			{}
		).subscribe((result) => console.log(result));
	}

	loadSideNavBehaviorOnPreferences() {
		this.apiPortalUserPreferencesService.get<MainControllerUserPreferences>(
			this.ePreferencesId.MAIN_CONTROLLER_PREFERENCES,
			{
				extras: {
					injectFriendlyMessageIntoResponseWhenEmpty: true
				}
			}
		).subscribe((result) =>
			this.setUserPreferences(result.body)
		);
	}

	setUserPreferences(data: MainControllerUserPreferences = this._userPreferences) {
		this._userPreferences = data;
		this.userPreferencesLoaded.next(true);
	}

	enableNavigationBar(){
		this.navigationBar = true;
	}

	disableNavigationBar(){
		this.navigationBar = false;
	}

	setSidenav(matSidenav: MatSidenav){
		this.sideNav = matSidenav;
	}

	getSidenav = (): MatSidenav => this.sideNav;

	closeSideNav(){
		if(this.sideNav){
			this.sideNav.close();
		}
	}

	openSideNav(){
		if(this.sideNav){
			this.sideNav.open();
		}
	}

	showBackButton(path: boolean | string = true){
		this._backButton = path;
	}

	hideBackButton(){
		this._backButton = false;
	}

	get backButton(){
		return this._backButton;
	}

	addRightIcons(component: Type<any>){
		this.rightIconsComponentAttached = component;
		if(this.rightIconsRef){
			setTimeout(() => {
				this.rightIconsRef.clear();
				this.rightIconsRef.createComponent(component);
			});
		}
	}

	refreshRightIcons(){
		if(this.rightIconsComponentAttached){
			this.addRightIcons(this.rightIconsComponentAttached);
		}
	}

	clearRightIcons(){
		if(this.rightIconsRef){
			this.rightIconsRef.clear();
		}
		this.rightIconsComponentAttached = null;
	}

}
