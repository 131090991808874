/* eslint-disable complexity */
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

const defaultProp: DateRelativeTimePipeProp = {
	mask: 'dhm',
	short: false,
	onlyNumbers: false,
};

@Injectable({
	providedIn: 'root'
})
@Pipe({
	name: 'dateRelativeTime'
})

export class dateRelativeTimePipe implements PipeTransform {

	shortDayLabel = this.translocoService.translate('Components.RelativeTime.ShortDayLabel');

	constructor(private translocoService: TranslocoService){}

	// mask
	// d = Days
	// h = hours
	// m = minutes
	transform(dateString: any, prop: DateRelativeTimePipeProp = defaultProp): string {
		return this.getRelativeTime(dateString, prop);
	}

	getRelativeTime(date: string, prop: DateRelativeTimePipeProp): string {

		// console.log(date);
		// 2022-12-07T12:50:38.000+00:00

		const minute = 1000 * 60;
		const hour = minute * 60;
		const day = hour * 24;

		const d1 = new Date(date);
		const d2 = new Date(prop.dateEnd || new Date());

		if(
			d2.getTime() <= d1.getTime()
			||
			d2.getTime() - d1.getTime() < minute
		){
			return prop.onlyNumbers ? '0' : this.translocoService.translate('Components.RelativeTime.Now');
		}

		const diff = new Date(d2.getTime() - d1.getTime());
		const days = Math.floor(diff.getTime() / day);
		const hours = Math.floor((diff.getTime() - days * day) / hour);
		const minutes = Math.floor((diff.getTime() - days * day - hours * hour) / minute);

		return (/d/.test(prop.mask) && days > 0
			? (prop.short
				? days + (prop.onlyNumbers ? '' : this.shortDayLabel)
				: this.translocoService.translate('Components.RelativeTimeDaysSelect', {days})
			)
			: (prop.sameDayShortLabel
				? (
					d1.getDate() === d2.getDate()
						? prop.sameDayShortLabel
						: '1' + (prop.onlyNumbers ? '' : this.shortDayLabel)
				)
				: ''
			)
		)

		+ (/h/.test(prop.mask) && hours > 0
			? (
				(days > 0 ? ', ' : '' )
				+ (prop.short
					? hours + (
						prop.onlyNumbers
							? ''
							: this.translocoService.translate('Components.RelativeTime.ShortDayHour')
					)
					: this.translocoService.translate('Components.RelativeTimeHoursSelect', {hours})
				)
			)
			: ''
		)

		+ (/m/.test(prop.mask) && minutes > 0
			? (
				(days+hours > 0 ? ', ' : '' )
				+ (prop.short
					? minutes + (
						prop.onlyNumbers
							? ''
							: this.translocoService.translate('Components.RelativeTime.ShortDayMinute')
					)
					: this.translocoService.translate('Components.RelativeTimeMinutesSelect', {minutes})
				)
			)
			: ''
		);

	}


}

export interface DateRelativeTimePipeProp {
	mask: string;
	short: boolean;
	onlyNumbers?: boolean;
	sameDayShortLabel?: string;
	dateEnd?: string;
}
